<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll-nav ref="nav" :current="navCurrent" v-if="detail">
        <cube-scroll-nav-panel key="基本信息" label="基本信息">
          <inputItem title="系统编号" :initialContent="detail.code" :disabled="true"></inputItem>
          <inputItem title="生效日期" :initialContent="formatDate(detail.time, 'yyyy-MM-dd')" :disabled="true"></inputItem>
          <inputItem title="合同编号" v-if="detail.contract_code && detail.contract_code!=detail.code" v-model="detail.contract_code" :disabled="true"></inputItem>
          <inputItem title="合同日期" v-if="detail.contract_date" :initialContent="formatDate(detail.contract_date, 'yyyy-MM-dd')" :disabled="true"></inputItem>
          <inputItem title="合同名称" v-model="detail.name" :disabled="disabled"></inputItem>
          <inputItem title="是否敲定" :initialContent="detail.confirm == 1 ? '已敲定' : '未敲定'" :disabled="disabled" name="confirm" type="select" :options="confirmOption" :contentChanged="contentChanged"></inputItem>
          <inputItem title="合同类型" v-model="detail.category" :disabled="disabled" type="select" :options="categoryOption"></inputItem>
          <inputItem title="供货单位" v-model="detail.owner" :disabled="disabled" type="select" :options="ownerOption"></inputItem>
          <inputItem title="业务部门" v-model="detail.dept" :disabled="disabled" type="select" :options="deptOption"></inputItem>
          <inputItem title="分摊比例" :initialContent="deptRatio(detail.ratio)" :disabled="disabled" name="ratio" type="select" :options="ratioOption" :contentChanged="contentChanged"></inputItem>
          <inputItem title="业务代表" v-model="detail.leader_name" :disabled="true"></inputItem>
          <inputItem title="客户名称" :initialContent="detail.customer_name" :disabled="disabled" type="others" ref="customer_name" :otherClicked="clickCutomer"></inputItem>
          <inputItem title="联系人员" v-model="detail.linkman" :disabled="disabled" ref="linkman"></inputItem>
          <inputItem title="联系电话" v-model="detail.link_tel" :disabled="disabled" ref="link_tel"></inputItem>
          <inputItem title="交货地点" v-model="detail.shipping_address" :disabled="disabled"></inputItem>
          <inputItem title="合同备注" v-model="detail.remark" :disabled="disabled"></inputItem>
          <itemTotal :total="total"></itemTotal>
        </cube-scroll-nav-panel>
        <!-- <cube-scroll-nav-panel key="扩展信息" label="扩展信息">
          <inputItem title="质保金" v-model="detail.quality_price" :disabled="disabled"></inputItem>
          <inputItem title="保证金" v-model="detail.bond_price" :disabled="disabled"></inputItem>
          <inputItem title="保证金收据" v-model="detail.bond_receipt" :disabled="disabled"></inputItem>
          <inputItem title="收款银行" v-model="detail.bank_name" :disabled="disabled"></inputItem>
          <inputItem title="收款账号" v-model="detail.bank_number" :disabled="disabled"></inputItem>
          <inputItem title="计划完工日期" :initialContent="formatDate(detail.plan_finished_time, 'yyyy-MM-dd')" :disabled="disabled" index="plan_finished_time" :contentChanged="contentChanged"></inputItem>
          <inputItem title="计划验收日期" :initialContent="formatDate(detail.plan_check_time, 'yyyy-MM-dd')" :disabled="disabled" index="plan_check_time" :contentChanged="contentChanged"></inputItem>
          <inputItem title="实际完工日期" :initialContent="formatDate(detail.fact_finished_time, 'yyyy-MM-dd')" :disabled="disabled" index="fact_finished_time" :contentChanged="contentChanged"></inputItem>
          <inputItem title="实际验收日期" :initialContent="formatDate(detail.fact_check_time, 'yyyy-MM-dd')" :disabled="disabled" index="fact_check_time" :contentChanged="contentChanged"></inputItem>
          <inputItem title="违约条款" v-model="detail.breach_clause" :disabled="disabled"></inputItem>
        </cube-scroll-nav-panel> -->
        <!-- <cube-scroll-nav-panel key="项目成员" label="项目成员">
          <div class="container">
            <div class="list" v-for="(item, index) in users" :key="index">
              <div class="flex-row space-between">
                <div class="item-index">成员{{ index + 1 }}</div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable">姓名</div>
                  <div class="fill-row text-left line-height-20">
                    {{ item.user_name }}
                  </div>
                </div>
                <div class="item-box-half">
                  <div class="item-lable">角色</div>
                  <div class="fill-row text-left line-height-20">
                    {{ item.role == 1 ? "联合跟进人" : "普通成员" }}
                  </div>
                </div>
                <div class="flex-row align-center space-between item-line">
                  <div class="item-lable">备注</div>
                  <div class="fill-row text-left line-height-20">
                    {{ item.remark }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </cube-scroll-nav-panel> -->
        <cube-scroll-nav-panel key="开票清单" label="开票清单">
          <div class="mobile-detail-list">
            <div class="list-item" v-for="(item, index) in sources" :key="index">
              <div class="item-header">
                <div class="item-index">开票清单{{ index + 1 }}</div>
                <img class="item-delete" src="@/static/images/icon_delete.png" alt @click="deleteSource(index)" v-if="!disabled" />
              </div>
              <div class="item-line">
                <div class="item-lable"> 商品名称 </div>
                <input class="input-left" v-model="item.goods_name" :disabled="disabled" />
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 销售数量 </div>
                  <input class="input-right" v-model="item.num" :disabled="disabled" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 销售单价 </div>
                  <input class="input-right" v-model="item.price" :disabled="disabled" />
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 销售金额 </div>
                  <input class="input-right" v-model="item.total" :disabled="disabled" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 销售备注 </div>
                  <input class="input-left" v-model="item.remark" :disabled="disabled" />
                </div>
              </div>
            </div>
            <div class="item-add" v-if="!disabled" alt @click="addSource"> 添加开票明细 </div>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="供货清单" label="供货清单">
          <div class="mobile-detail-list">
            <div class="list-item" v-for="(item, index) in items" :key="index">
              <div class="item-header">
                <div class="item-index">供货清单{{ index + 1 }}</div>
              </div>
              <div class="item-line">
                <div class="item-lable"> 商品名称 </div>
                <div class="fill-row text-left line-height-20"> {{ item.goods_name }} </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 销售数量 </div>
                  <input class="input-right" v-model="item.num" :disabled="disabled" @input="changeNum(item)" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 销售单价 </div>
                  <input class="input-right" v-model="item.price" :disabled="disabled" @input="changeNum(item)" />
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 销售金额 </div>
                  <input class="input-right" v-model="item.total" :disabled="disabled" @input="changeTotal(item)" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 询价成本 </div>
                  <div class="input-right"> {{ item.pre_cost }} </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 合计毛利 </div>
                  <div class="input-right"> {{ item.pre_profit }} </div>
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 总毛利率 </div>
                  <div class="input-right"> {{ item.pre_rate }} </div>
                </div>
              </div>
              <div class="item-line">
                <div class="item-lable"> 销售备注 </div>
                <input class="input-left" v-model="item.remark" :disabled="disabled" />
              </div>
            </div>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="相关附件" label="相关附件">
          <uploadBox v-model="attachs" :allowed="buttons && (buttons.includes('保存草稿') || buttons.includes('保存附件'))"> </uploadBox>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="审批流程" label="审批流程" v-if="flows && flows.flowLogs && flows.flowLogs.length > 0">
          <div class="mobile-detail-step">
            <Steps :current="flows.step" :status="flows.status" direction="vertical">
              <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
            </Steps>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="沟通记录" label="沟通记录">
          <div class="timeline-box">
            <Timeline>
              <TimelineItem v-for="(re, index) in replyList" :key="index">
                <p class="timeline-time">{{re.user_name+ ''+(re.remind_user_names? ' @ '+re.remind_user_names:'')+ ' '+formatDate(re.time, 'MM-dd hh:mm')+(re.isread=='1'?'（已读）':'')}}</p>
                <p class="timeline-content">{{re.content}}</p>
              </TimelineItem>
            </Timeline>
            <div class="timeline-reply">
              <personSelect v-model="replyUser" title="@人员（可选，默认@业务代表）" :necessary="false" :disabled="false"></personSelect>
              <Input type="textarea" v-model="replyContent" :rows="6" placeholder="请输入内容..." style="width: 100%;margin:5px 0 10px;"></Input>
              <Button type="primary" @click="addReply()" style="width: 100%;">回复</Button>
            </div>
          </div>
        </cube-scroll-nav-panel>
      </cube-scroll-nav>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
    <optionDrawer ref="cutomerDrawer" v-if="showCutomers" :onBgClicked="onBgClicked" :onSearch="onSearchCutomer" :items="cutomers" @optionClicked="cutomerClicked"></optionDrawer>
  </div>
</template>

<script>
import { baseURL, GetProject, SaveProject, AuditProject, CancelProject, GetReply, ReplyDaily } from "@/api";
import { projectState } from "@/utils/state.js";
import { formatDate, formatMoney } from "@/utils/format.js";
import { loadDetailAction, submitAction, auditAction, cancelAction, loadDataOptionAction, loadCustomerOptionAction, loadDataAction, saveAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      users: [],
      sources: [],
      items: [],
      attachs: [],
      flows: [],
      buttons: [],
      total: {
        total: null,
        cost: null,
        profit: null,
        rate: null,
        comix_tariff: null,
        comix_price: null,
      },
      disabled: false,
      categoryOption: [],
      ownerOption: [],
      deptOption: [],
      ratioOption: [],
      confirmOption: [{ text: '已敲定', value: 1 }, { text: '未敲定', value: 0 }],
      cutomers: [],
      showCutomers: false,
      baseURL: baseURL,
      replyContent: null,
      replyList: [],
      replyUser: [],
      navCurrent: "基本信息",
    };
  },
  methods: {
    projectState: projectState,
    formatDate: formatDate,
    formatMoney: formatMoney,
    contentChanged: function (index, value) {
      switch (index) {
        case "confirm":
          this.detail.confirm = value;
          break;
        case "ratio":
          this.detail.ratio = value;
          break;
      }
    },
    deptRatio: function (value) {
      var res = this.ratioOption.filter(c => c.value == value);
      return (res && res.length > 0) ? res[0].text : "";
    },
    onSearchCutomer: function (value) {
      loadCustomerOptionAction(this, value, (data) => { this.cutomers = data; });
    },
    onBgClicked: function () {
      this.showCutomers = false;
    },
    cutomerClicked: function (item) {
      this.detail.customer_id = item.id;
      this.detail.customer_name = item.name;
      this.detail.linkman = item.exts[0];
      this.detail.link_tel = item.exts[1];
      this.$refs.customer_name.setContent(item.name);
      this.$refs.linkman.setContent(item.exts[0]);
      this.$refs.link_tel.setContent(item.exts[1]);
      this.showCutomers = false;
    },
    clickCutomer: function () {
      this.showCutomers = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.cutomerDrawer.show();
        }, 100);
      });
    },
    addSource: function () {
      this.sources.push({ goods_name: "", num: 1, price: 0, total: 0, remark: "" });
    },
    deleteSource: function (index) {
      this.sources.splice(index, 1);
    },
    changeNum: function (item) {
      item.total = item.price * item.num;
      this.setTotal();
    },
    changeTotal: function (item) {
      item.price = item.total / item.num;
      this.setTotal();
    },
    setTotal: function () {
      var tot = this.total;
      tot.total = 0;
      tot.cost = 0;
      tot.profit = 0;
      tot.rate = 0;
      tot.comix_tariff = 0;
      tot.comix_price = 0;
      this.items.forEach(function (item) {
        tot.total += Number(item.total);
        tot.cost += Number(item.pre_cost);
      });
      tot.profit = tot.total - tot.cost - tot.comix_price;
      tot.rate = tot.total == 0 ? 0 : ((tot.profit * 100) / tot.total).toFixed(2);
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveProject(1);
          break;
        case "提交审核":
          this.saveProject(2);
          break;
        case "审核通过":
          this.auditProject(true, "同意");
          break;
        case "审核拒绝":
          this.auditProject(false, "");
          break;
        case "撤回":
          this.cancelProject();
          break;
      }
    },
    saveProject: function (state) {
      this.detail.state = state;
      submitAction(this, SaveProject, { model: this.detail, items: this.items, sources: this.sources, attachs: this.attachs }, () => { this.$router.go(-1); }, () => { this.detail.state = 1; }, (state == 2), "是否确定提交？");
    },
    auditProject: function (pass, memo) {
      auditAction(this, AuditProject, this.id, pass, memo);
    },
    cancelProject: function () {
      cancelAction(this, CancelProject, this.id);
    },
    getReply: function () {
      loadDataAction(this, GetReply, { id: this.id }, (data) => {
        this.replyList = data;
        this.replyContent = null;
        this.replyUser = [];
      });
    },
    addReply: function () {
      var remindIds = this.replyUser.map(item => item.id).join(',');
      var remindNames = this.replyUser.map(item => item.name).join(',');
      saveAction(this, ReplyDaily, { model: { daily_id: this.id, type: 1, content: this.replyContent, order_name: "项目信息", remind_user_ids: remindIds, remind_user_names: remindNames } }, () => { this.getReply(); }, () => { });
    },
    getUser: function () {
      loadDataAction(this, GetReply, { id: this.id }, (data) => {
        this.replyList = data;
      });
    },
    dataRequest: function () {
      loadDetailAction(this, GetProject, { id: this.id, type: this.orderType }, (data) => {
        this.detail = data.list[0].model;
        this.users = data.list[0].users;
        this.sources = data.list[0].sources;
        this.items = data.list[0].items;
        this.attachs = data.list[0].attachs;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.disabled = !this.buttons || this.buttons.indexOf("保存草稿") < 0;
        this.setTotal();
        this.getReply();
        this.$nextTick(() => {
          this.$refs.nav.refresh();
          setTimeout(() => {
            if (this.$route.query.current) {
              this.navCurrent = this.$route.query.current;
            }
          }, 800);
        });
      });
    },
  },
  mounted() {
    loadDataOptionAction(this, "合同类型", (data) => { this.categoryOption = data; });
    loadDataOptionAction(this, "业务归属", (data) => { this.ownerOption = data; });
    loadDataOptionAction(this, "业务部门", (data) => { this.deptOption = data; });
    loadDataOptionAction(this, "分摊比例", (data) => { this.ratioOption = data; }, true);

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>
<style scoped>
.timeline-box {
  padding: 10px;
  background: #fff;
  text-align: left;
}
.timeline-reply {
  text-align: right;
}
.timeline-time {
  font-size: 14px;
  font-weight: bold;
  margin-top: 2px;
}

.timeline-content {
  white-space: pre-wrap;
  line-height: 1.2;
  padding-top: 5px;
}
</style>